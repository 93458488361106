$ = require('jquery')

require('../jquery-ui')
require('../datepick-cs')

exports.init = ->

	lang = if $('html').prop('lang') is 'cs' then 'cs' else ''

	$('.inp-date')
		.datepicker()
		.datepicker( "option", $.datepicker.regional[ lang ] )
