$ = require('jquery')

exports.init = ->
	$('.box-toggle .item')
		.each ->
			$item    = $(this)
			$title   = $item.find('.title')
			$content = $item.find('.content')

			$title
				.on 'click', (event) ->
					event.preventDefault()

					newHeight = if $content.height() > 0 then 0 else $content.prop('scrollHeight')

					$content.animate
						height: newHeight
						400
						->
							if newHeight > 0
								$content.css
									height: 'auto'

					$item
						.toggleClass('open')
						.toggleClass('close')
