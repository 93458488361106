$ = require('jquery')
require('./bindPolyfill')
require('headroom.js')

$document = $(document)
dHeight = $document.height()
$window = $(window)
wHeight = $window.height()
wScrollCurrent = 0
wScrollBefore = 0
wScrollDiff = 0


exports.init = ->

	if !$("#header").length
		return false

	headroom = new Headroom $("#header").get(0)

	.init()
