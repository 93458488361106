$ = require('jquery')

exports.init = ->
	# Google map
	$('.box-map').each ->

		location = $(this).data('location').split(',')
		loc1 = location[0]
		loc2 = location[1]

		mapStyles = [
			{
				"featureType": "administrative",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#444444"
					}
				]
			},
			{
				"featureType": "administrative.land_parcel",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "landscape",
				"elementType": "all",
				"stylers": [
					{
						"color": "#dcdedd"
					}
				]
			},
			{
				"featureType": "landscape.natural",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "on"
					},
					{
						"color": "#babac4"
					},
					{
						"saturation": "0"
					},
					{
						"lightness": "1"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "labels",
				"stylers": [
					{
						"visibility": "simplified"
					},
					{
						"lightness": "-53"
					},
					{
						"weight": "1.00"
					},
					{
						"gamma": "0.98"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "road",
				"elementType": "all",
				"stylers": [
					{
						"saturation": "-100"
					},
					{
						"lightness": "40"
					},
					{
						"visibility": "on"
					}
				]
			},
			{
				"featureType": "road",
				"elementType": "geometry",
				"stylers": [
					{
						"saturation": "-18"
					}
				]
			},
			{
				"featureType": "road",
				"elementType": "labels",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "on"
					},
					{
						"saturation": "-100"
					}
				]
			},
			{
				"featureType": "road.arterial",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "on"
					}
				]
			},
			{
				"featureType": "road.arterial",
				"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "road.local",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "on"
					}
				]
			},
			{
				"featureType": "transit",
				"elementType": "all",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "all",
				"stylers": [
					{
						"color": "#accfd5"
					},
					{
						"visibility": "on"
					}
				]
			}
		]

		styledMap = new google.maps.StyledMapType(mapStyles, {name: "Map"});

		mapOptions =
			center: new google.maps.LatLng(loc1, loc2)
			zoom: 15
			mapTypeId: google.maps.MapTypeId.ROADMAP
			scrollwheel: false
			disableDefaultUI: false
			mapTypeControl: false
			mapTypeControlOptions:
				style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR
				position: google.maps.ControlPosition.BOTTOM_CENTER
			zoomControl: true
			zoomControlOptions:
				style: google.maps.ZoomControlStyle.LARGE
				position: google.maps.ControlPosition.LEFT_CENTER
			scaleControl: true
			panControl: true
			panControlOptions:
				position: google.maps.ControlPosition.LEFT_CENTER
			scaleControlOptions:
				position: google.maps.ControlPosition.LEFT_CENTER
			streetViewControl: false
			streetViewControlOptions:
				position: google.maps.ControlPosition.LEFT_CENTER
				

		map = new google.maps.Map(this, mapOptions)
		map.mapTypes.set('greyscale', styledMap);
		map.setMapTypeId('greyscale');

		map.panBy(0, - $('#header').height() / 2 )

		marker = new google.maps.Marker
			position: new google.maps.LatLng(loc1, loc2)
			map: map
			icon: '/bundles/trfrontend/img/bg/map-pin.png'
