$ = require('jquery')

exports.init = ->
	$('.box-mobile-popup').each ->
		$box = $(this)
		$btn = $box.find('.js-show-popup')
		$close = $box.find('.btn-close')
		$content = $box.find('.content')

		$btn.on 'click', (event) ->
			event.preventDefault()

			$box.css
				minHeight: $box.outerHeight()

			$btn.fadeOut 200, ->
				$content.animate
					height: $content.prop('scrollHeight')
				,
					duration: 400
					queue: false

				$content.delay(100, 'fade').animate
					opacity: 1
				,
					duration: 300
					queue: 'fade'
				.dequeue('fade')
				
				$('html, body').animate
					scrollTop: $content.offset().top
				,
					duration: 400
					queue: false

		$close.on 'click', (event) ->
			event.preventDefault()

			$content.animate
				opacity: 0
			,
				duration: 300
				queue: false

			$content.animate
				height: 0
			,
				duration: 400
				complete: ->
					$btn.fadeIn(200)
