$ = require('jquery')

exports.init = ->
	$('.form-register').each ->
		$form = $(this)

		$button = $('.js-show-register')
		$goto = $('.js-goto-register')
		$text = $button.find('span')

		$button
			.on 'click', (event) ->
				event.preventDefault()

				if $form.is(':visible')
					$text.text($button.data('show'))
					$button.removeClass('btn-white').addClass('btn-yellow')
					$form.slideUp()
				else
					$button.hide()
					$form.slideDown()
					# $text.text($button.data('hide'))
					# $button.removeClass('btn-yellow').addClass('btn-white')

		$goto
			.on 'click', (event) ->
				event.preventDefault()
				
				if $form.is(':visible')
					$('body, html').animate({scrollTop: $form.offset().top}, 600)
				else
					$form.slideDown()
					$('body, html').animate({scrollTop: $form.offset().top}, 600)