$ = require('jquery')

require('../sk/sk')
require('../sk/sk.widgets.Thickbox')
appDatepicker = require('./datepicker')

exports.init = ->
	thickbox = new sk.widgets.Thickbox()
	thickbox.control('.thickbox')
	thickbox.width = 660
	thickbox.onLoad = ($box) ->
		$box
			.find('select')
			.select2
				minimumResultsForSearch: -1
				dropdownCssClass: 'select-modal'

		appDatepicker.init()
