$ = require("./../../../../../../../bower_components/jquery/dist/jquery.js")
exports.init = function() {
	
	$('body').on('submit', 'form.form-modal', function() {
		
		var action = $(this).attr('action');
		var parent = $(this).parent();
		
		if (parent.find('input[name~="url"]').val() != '') {
			return false;
		}
		
		parent.find('button').prop('disabled', true);
		
		$.post(action, $(this).serialize(), function(data) {
			
			var lang = ($('html').prop('lang') == 'cs') ? 'cs' : '';
			
			parent.find('button').removeProp('disabled');

			parent.html(data);
			parent.find('.inp-date')
				.datepicker()
				.datepicker( "option", $.datepicker.regional[ lang ] );
			
			if (parent.closest('.box-mobile-popup').length) {
				
				var content = parent.closest('.content');
				var newHeight = 0;
				content.children().each(function() {
					newHeight += $(this).outerHeight(true);
				})
				content.animate({
					'height': newHeight
				});
				$('body, html').animate({
					'scrollTop': content.offset().top-67
				});
			}
			
		});
		
		return false;
	});
	
}