$ = require('jquery')
window.jQuery ?= $

appEqualizeHeights = require('./app/equalizeHeights')
appFixedHeader = require('./app/fixedHeader')
appMasonry = require('./app/masonry')
appMenuMain = require('./app/menuMain')
appMap = require('./app/map')
appThickbox = require('./app/thickbox')
appDatepicker = require('./app/datepicker')
appAccordeon = require('./app/accordeon')
appFooter = require('./app/footer')
appRegister = require('./app/register')
appRegister2 = require('./app/register2')
appBook = require('./app/book')
appSearch = require('./app/search')
appInstagram = require('./app/instagram')
appVimeo = require('./app/vimeo')
appMedia = require('./app/media')
appMobilePopup = require('./app/mobilePopup')
appCarousel = require('./app/carousel')

window.App =
	run: ->
		$('select').each ->
			$select = $(this)

			if $select.closest('.form-register').length
				$select.select2
					minimumResultsForSearch: -1
					dropdownCssClass: 'dark'

			else if $select.closest('.talent-filter').length
				$select.select2
					minimumResultsForSearch: -1
					allowClear: true

			else
				$select.select2
					minimumResultsForSearch: -1

		appMap.init()
		appFixedHeader.init()
		appMenuMain.init()
		appThickbox.init()
		appDatepicker.init()
		appAccordeon.init()
		appRegister.init()
		appMobilePopup.init()
		appCarousel.init()

		appBook.init()
		appSearch.init()
		appInstagram.init()
		appVimeo.init()
		appRegister2.init()
		appMedia.init()

		$(window).load( ->

			appEqualizeHeights.init()
			appMasonry.init()
			appFooter.init()

		)

		return

	instagramCallback: appInstagram.parseData
