$ = require('jquery')

exports.init = ->
	$colTalents = $('.box-links .col-talents')
	if $colTalents.length
		$boxLinksBg = $('.box-links .bg')
		colTalents_left = $colTalents.offset().left
		$boxLinksBg.css( 'left', colTalents_left )

		$(window).resize( ->

			colTalents_left = $colTalents.offset().left
			$boxLinksBg.css( 'left', colTalents_left )

			return

		).trigger('resize')
