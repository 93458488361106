$ = require('jquery')
require('../sk/skGet')

# Equalize heights
eq = ($element, item, count1) ->
	$element.each( ->
		$items = $(this).find(item).css('height', '')
		end = $items.length
		count = if count1 then count1 else parseInt($(this).data('eq'))
		i = 0
		while i < end
			$items.slice(i, i + count).skGet('max', 'height', (val) ->
				@height(val)
				return
			)
			i = i + count
		return
	)
	return

exports.init = ->
	
	$(window).resize( ->

		eq($('.box-links.is-talents'), '.item:visible', 3)
		eq($('.crossroad-hp'), '.desc', 3)

		return

	).trigger('resize')
	