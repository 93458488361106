$ = require('jquery')

Masonry = require('masonry-layout')

exports.init = ->
	if $('.crossroad-blog').length
		msnry = new Masonry( '.crossroad-blog', {
			columnWidth: '.item',
			itemSelector: '.item',
			gutter: 18
		})
