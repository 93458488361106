$ = require("./../../../../../../../bower_components/jquery/dist/jquery.js")
require('../sk/sk')
require('../sk/sk.widgets.Thickbox')

exports.init = function() {
	
	if (!$('.form-register').length)
		return false;
	
	
	$('body').on('click', 'a.ajax-register', function() {
		var action = $(this).attr('href');
		var target = $('.register-target');
		$.get(action, function(response) {
			$('body, html').animate({scrollTop: target.offset().top-77}, 600)
			target.html(response);
			reinit(true);
			return false;
		});
		return false;
	});
	
	$('body').on('submit', 'form.ajax-register', function() {
		
		if ($('.photo-drop').length && !$('.photo-drop img').length) {
			var text = $('.photo-drop').attr('data-text');
			var box = new sk.widgets.Thickbox();
			box.openHtml('<p>' + text + '</p>');
			return false;
		}
		
		if ($('#conditions').length && !$('#conditions').is(':checked')) {
			var text = $('#conditions').attr('data-text');
			var box = new sk.widgets.Thickbox();
			box.openHtml('<p>' + text + '</p>');
			return false;
		}
		
		var talents = $('input#speaker, input#singer, input#musician');
		if (talents.length && !talents.is(':checked')) {
			var text = talents.attr('data-text');
			var box = new sk.widgets.Thickbox();
			box.openHtml('<p>' + text + '</p>');
			return false;
		}
		var talentsRequired = true;
		talents.each(function() {
			if (talentsRequired == false)
				return false;
			if ($(this).is(':checked')) {
				var content = $('#' + $(this).attr('id') + '-content');
				var selects = content.find('select[required]');
				selects.each(function(key, select) {
					if ($(select).val() == null || $(select).val().length == 0) {
						var text = $(select).attr('data-text');
						var box = new sk.widgets.Thickbox();
						box.openHtml('<p>' + text + '</p>');
						talentsRequired = false;
						return false;
					}
				});
			}
		});
		if (talentsRequired == false)
			return false;
		
		if ($('.person-demo, .person-video').length) {
			if ($('.person-demo .item, .person-video .item').length == 0) {
				var text = $('.person-demo').attr('data-text');
				var box = new sk.widgets.Thickbox();
				box.openHtml('<p>' + text + '</p>');
				return false;
			}
		}
		
		var action = $(this).attr('action');
		var data = $(this).serialize();
		var target = $('.register-target');
		$.post(action, data, function(response) {
			$('body, html').animate({scrollTop: target.offset().top-77}, 600)
			target.html(response);
			reinit(true);
			return false;
		});
		return false;
	})
	
	var reinit = function(select2) {
		
		var select2 = select2 || false;
		
		switches = $('.form-register .talents input:checkbox')
		switches.on('change', function() {
			var target = $($(this).attr('data-target'));
			target.toggle( $(this).prop('checked') );
		}).trigger('change');
		
		if (typeof $().fileupload != 'undefined') {
		
			$('.person-demo input[type="file"]').fileupload({
				dataType: 'html',
				dropZone: null,
		        done: function (e, data) {
		        	$('.js-person-demo-target').html(data.result);
		        },
			    acceptFileTypes: /(\.|\/)(mp3)$/i,
			    maxFileSize: 8*1024*1024, // 8 MB
			    minFileSize: undefined, // No minimal file size
			    messages: errorMessages
			}).on('fileuploadprocessalways', function (e, data) {
			    var currentFile = data.files[data.index];
			    if (data.files.error && currentFile.error) {
					var box = new sk.widgets.Thickbox();
					box.openHtml('<p>' + currentFile.error + '</p>');
			    }
			});
			
			$('.person-image input[type="file"]').fileupload({
				dataType: 'html',
				dropZone: $('.person-image .zone'),
		        done: function (e, data) {
		        	$('.js-person-image-target').html(data.result);
		        },
		        acceptFileTypes: /(\.|\/)(jpe?g|png)$/i,
			    maxFileSize: 8*1024*1024, // 8 MB
			    minFileSize: undefined, // No minimal file size
			    messages: errorMessages
			}).on('fileuploadprocessalways', function (e, data) {
				var currentFile = data.files[data.index];
				if (data.files.error && currentFile.error) {
					var box = new sk.widgets.Thickbox();
					box.openHtml('<p>' + currentFile.error + '</p>');
			    }
			});
			
		}
		
		if (select2) {
			$('select').each(function() {
				$select = $(this)
				
				if ($select.closest('.form-register').length)
					$select.select2({
						minimumResultsForSearch: -1,
						dropdownCssClass: 'dark'
					});
				else if ($select.closest('.talent-filter').length)
					$select.select2({
						minimumResultsForSearch: -1,
						allowClear: true
					});
				
				else
					$select.select2({
						minimumResultsForSearch: -1
					});
			});
		}
	}
	
	if (typeof $.fancybox != 'undefined') {
		$(".btn-preview").fancybox({
			'width'				: '1280',
			'height'			: '800',
	        'autoScale'     	: false,
	        'transitionIn'		: 'none',
			'transitionOut'		: 'none',
			'type'				: 'iframe',
			'iframe': {
				preload: false
			},
			'helpers': {
				overlay: {
					locked: false
				}
			}
		});
	}
	
	// demo samples
	
	$('body').on('click', '.person-demo .btn-remove', function() {
		$.post($(this).attr('href'), function(result) {
			$('.js-person-demo-target').html(result);
		});
		return false;
	});
	
	
	// video samples
	
	$('body').on('click', '.person-video .btn-add', function() {
		var prompt = $(this).attr('data-prompt');
		var result = window.prompt(prompt);
		
		if (result != null) {
			var url = $('.person-video-form').attr('action');
			$('.video-url').val(result);
			var data = $('.person-video-form').serialize();
			$.post(url, data, function(result) {
				$('.js-person-video-target').html(result);
			})
		}
		return false;
	});
	
	$('body').on('click', '.person-video .btn-remove', function() {
		$.post($(this).attr('href'), function(result) {
			$('.js-person-video-target').html(result);
		});
		return false;
	});
	
	
	// image upload
	
	$(document).bind('drop dragover', function (e) {
		e.preventDefault();
	});
	
	reinit();
	
}