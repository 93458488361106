$ = require('jquery')

exports.init = ->
	$('#menu-main')
		.each( ->

			$title = $(this).find('.title')
			$body = $('body')

			$title.on('click', (event) ->
				event.preventDefault()
				$body.toggleClass('menu-open')
			)

		)
