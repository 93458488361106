do ($ = jQuery) ->
	$.fn.skGet = (get, dimension, callback) ->
		unless this.length
			return this

		result = null
		m = Math[get]
		this.each((index) ->
			newResult = $(this)[dimension]()
			result = if index then m(result, newResult) else newResult
		)

		if typeof callback is 'function'
			callback.call(this, result)
			return this

		return result
