$ = require('jquery')
require('jquery-cycle2')

exports.init = ->
	$('.box-intro, .box-profile-create, .box-studio, .box-how-works').each ->
		$box = $(this)
		$bg = $box.find('.bg')
		$content = $box.find('.content')

		$bg
			.cycle
				slides: '.bg-item'
				speed: 1000
				timeout: 10000
				autoHeight: -1
				log: false
				next: $box.find('.arrow-right')
				prev: $box.find('.arrow-left')
			.on 'cycle-before', ->
				$content.cycle('next')

		$content
			.cycle
				slides: '.content-item'
				speed: 1000
				timeout: 0
				log: false
