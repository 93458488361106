$ = require("./../../../../../../../bower_components/jquery/dist/jquery.js")

exports.init = function() {
	
	var resizeGridHeight = function() {
		
		if ($(window).width() <= 600) {
			var maxHeight = 'auto';
		} else {
			var maxHeight = 0;
			$('.crossroad-profiles .bd dl').height('auto');
			$('.crossroad-profiles .bd dl').each(function() {
				var height = $(this).height();
				if (maxHeight < height)
					maxHeight = height;
			});
		}
		
		$('.crossroad-profiles .bd dl').height(maxHeight);
	}
	
	var fixSelect2 = function() {
		$(".talent-filter .select2-search, .talent-filter .select2-focusser").remove();
	}
	
	resizeGridHeight();
	fixSelect2();
	$(window).on('resize', resizeGridHeight);
	
	$('body').on('change', '.talent-filter select', function() {
		
		var form = $(this).closest('form');
		var url = form.attr('action');
		var target = form.attr('data-target');

		$.post(url, form.serialize(), function(data) {
			$(target).html(data);
			resizeGridHeight();
			$(target).find('.talent-filter select').select2({
				allowClear: true,
				minimumResultsForSearch: -1
			});
			fixSelect2();
		});
		
	});
	
}