$ = require("./../../../../../../../bower_components/jquery/dist/jquery.js")

exports.init = function() {
	
	
	var stickKit = function() {
		if ($(window).width() < 768)
			$(".interpret-img").trigger('sticky_kit:detach');
		else
			$(".interpret-img").stick_in_parent();
	}
	
	$(window).on('resize', stickKit);
	stickKit();
	
	if (typeof audiojs != 'undefined')
		audiojs.createAll();
	
	if (typeof $.fancybox != 'undefined') {
		
		$(".fancybox").fancybox({
			'helpers': {
				overlay: {
					locked: false
				}
			}
		});
		
		$('.iframe').on('click', function() {

			if ($(window).width() < 768) {
				
				if ($(this).attr('target') != '_blank') {
					window.open($(this).attr('href'));
					return false;
				}
				return true;
			}
			
			var url = $(this).attr('data-embed');
			if (typeof url == 'undefined')
				url = $(this).attr('href');

			$.fancybox.open({
				'width'				: '900',
				'height'			: '500',
				'autoScale'     	: false,
				'transitionIn'		: 'none',
				'transitionOut'		: 'none',
				'type'				: 'iframe',
				'href'				: url,
				'iframe': {
					preload: false
				},
				'helpers': {
					overlay: {
						locked: false
					}
				}
			});

			return false;
		});
	
	}

}