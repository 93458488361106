$ = require("./../../../../../../../bower_components/jquery/dist/jquery.js")
$ = require("./../../../../../../../bower_components/swiper/dist/js/swiper.js")

exports.init = function() {
	
//	this.container = $('.crossroad-instagram');
//	if (this.container.length) {
//	
//		$.ajax('https://api.instagram.com/v1/users/1909255553/media/recent', {
//			jsonp: true,
//			dataType: 'jsonp',
//			data: {
//				callback: 'App.instagramCallback',
//				client_id: 'b65180b9e3f543079d349748500a8f2c'
//			}
//		});
//
//	}
	
};

exports.parseData = function(data) {
	
	var container = $('.crossroad-instagram .scroll');
	
	var MAX_TEXT_LENGTH = 36;
	var MAX_TAGS = 4;
	
	$(data.data).each(function(key, insta) {
		
		var item = $('<li class="item swiper-slide" />');
		var link = $('<a class="inner" target="_blank" />');
		link.attr('href', insta.link);
		var imgc = $('<p class="img" />');
		
		var image = $('<img />');
		image.attr('src', insta.images.standard_resolution.url);
		
		item.append(link);
		imgc.append(image);
		link.append(imgc);
		
		if (insta.caption) {
			var caption = $('<div class="desc" />');
			var text = $('<p />');
			var cleared = insta.caption.text.replace(/#[0-9a-zA-Z-_]+/g, '').trim();
			
			if (cleared.length > MAX_TEXT_LENGTH) {
				cleared = cleared.substr(0, MAX_TEXT_LENGTH) + '&hellip;';
			}
			
			text.html(cleared);
			
			for (key in insta.tags) {
				if (key >= MAX_TAGS)
					break;
				text.append($('<span class="hash"> #'+insta.tags[key]+'</span>'));
			}
			
			caption.append(text);
			link.append(caption);
		}
		
		container.append(item);
	});
	
	var swiper = new Swiper ('.crossroad-instagram.swiper-container', {
		slidesPerView: 'auto',
		nextButton: '.crossroad-instagram.swiper-container .swiper-button-next',
    	prevButton: '.crossroad-instagram.swiper-container .swiper-button-prev',
	});
	
	var swiper = new Swiper ('.crossroad-profiles.swiper-container', {
		slidesPerView: 'auto', 
		nextButton: '.crossroad-profiles.swiper-container .swiper-button-next',
    	prevButton: '.crossroad-profiles.swiper-container .swiper-button-prev',
	});

};